import React from 'react';
import { Link } from 'react-router-dom';
import backgroundImg from '../assets/images/background.jpg'; // Update path as needed

const Main = () => {
    return (
        <section className="relative w-full h-screen flex items-center justify-center overflow-hidden">
            {/* Background Image */}
            <div
                className="absolute inset-0 bg-cover bg-center"
                style={{ backgroundImage: `url(${backgroundImg})` }}
            >
                <div className="absolute inset-0 bg-black opacity-50"></div>
            </div>

            {/* Content */}
            <div className="relative z-10 text-center text-white px-6 py-12 md:px-12 md:py-24">
                <div className="animate__animated animate__fadeIn animate__delay-1s">
                    <p className="text-2xl md:text-3xl lg:text-4xl font-semibold mb-4">
                        Discover the World of Knowledge with
                    </p>
                    <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-6 animate__animated animate__fadeIn animate__delay-2s">
                        Silver Rose Publications
                    </h1>
                    <p className="text-lg md:text-xl mb-8 animate__animated animate__fadeIn animate__delay-3s">
                        Embark on a journey through our curated collection of books and resources, designed to inspire and educate.
                    </p>
                    <Link
                        to="/"
                        className="bg-gradient-to-r from-teal-500 to-cyan-600 text-white px-6 py-3 rounded-lg font-semibold text-lg transition-transform transform hover:scale-105 hover:opacity-90 animate__animated animate__fadeIn animate__delay-4s"
                    >
                        Explore Our Collection
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default Main;
