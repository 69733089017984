




// import React from 'react';
// import logo from '../assets/images/book.png';
// import { Link } from 'react-router-dom';

// const ClassCard = (props) => {
//   const { url, bookname, classNameForPdf, fileId } = props;
//   console.log(fileId, url, classNameForPdf )
//   const encodedClassName = encodeURIComponent(classNameForPdf);
//   const encodedSubjectName = encodeURIComponent(bookname);

//   return (
//     <div className="card-container mx-auto p-4">
//       <div className="card bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-xl">
//         <div className="card-image">
//           <img src={logo} className="w-full h-40 object-cover" alt={bookname} />
//         </div>
//         <div className="card-content p-4">
//           <p className='uppercase text-lg font-semibold text-gray-800 mb-2 text-center'>{bookname}</p>
//           <div className="card-actions flex flex-col space-y-2">
//             <Link
//               to={`/videos/${url}`}
//               className='w-full px-4 py-2 rounded-full text-sm text-white bg-blue-500 hover:bg-blue-600 hover:shadow-md hover:shadow-blue-500 transition-transform duration-300 transform hover:scale-105'
//             >
//               View by Chapter/Video
//             </Link>
//             {/* <Link
//               to={`${fileId}`}
//               className='w-full px-4 py-2 rounded-full text-sm text-white bg-red-500 hover:bg-red-600 hover:shadow-md hover:shadow-red-500 transition-transform duration-300 transform hover:scale-105'
//             >
//               Read E-Book
//             </Link> */}
//             <Link
//               to={`/flipbook/${encodedClassName}/${encodedSubjectName}`}
//               state={{ fileId }}
//               className='w-full px-4 py-2 rounded-full text-sm text-white bg-red-500 hover:bg-red-600 hover:shadow-md hover:shadow-red-500 transition-transform duration-300 transform hover:scale-105'
//             >
//               Read E-Book
// </Link>

//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ClassCard;

import React from 'react';
import logo from '../assets/images/book.png'; // Make sure this path is correct
import { Link } from 'react-router-dom';

const ClassCard = (props) => {
  const { url, bookname, classNameForPdf, fileId } = props;
  const encodedClassName = encodeURIComponent(classNameForPdf);
  const encodedSubjectName = encodeURIComponent(bookname);

  return (
    <div className="card-container max-w-sm mx-auto p-4">
      <div className="card bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
        <div className="card-image">
          <img src={logo} className="w-full h-48 object-cover" alt={bookname} />
        </div>
        <div className="card-content p-6">
          <h3 className='text-xl font-semibold text-gray-800 mb-4 text-center'>{bookname}</h3>
          <div className="card-actions flex flex-col gap-3">
            <Link
              to={`/videos/${url}`}
              className='block w-full px-4 py-2 rounded-lg text-center text-sm text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-300'
            >
              View by Chapter/Video
            </Link>
            <Link
              to={`/flipbook/${encodedClassName}/${encodedSubjectName}`}
              state={{ fileId }}
              className='block w-full px-4 py-2 rounded-lg text-center text-sm text-white bg-red-600 hover:bg-red-700 transition-colors duration-300'
            >
              Read E-Book
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassCard;
