import React, { useState, useEffect } from 'react';

// Import your background image
import backgroundImage from '../assets/images/book4.jpg'; // Replace with your actual image path

const DynamicTextAnimation = ({ message }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setVisible((prev) => !prev);
    }, 2000); // Toggle visibility every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className="relative w-full h-[50vh] bg-cover bg-center flex items-center justify-center overflow-hidden"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {/* Overlay for readability */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-0"></div>
      
      <div className="relative z-10 p-8 text-center">
        <h1
          className={`text-3xl md:text-4xl lg:text-5xl font-extrabold text-white ${visible ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000`}
        >
          {message}
        </h1>
        <p className="mt-4 text-lg md:text-xl text-white opacity-80">
          Embrace the journey of exploration and knowledge.
        </p>
      </div>
    </section>
  );
};

export default DynamicTextAnimation;
