import React from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { FaAddressCard } from 'react-icons/fa';

const ContactUsLeft = () => {
  return (
    <div className="contact-us w-full md:w-1/2 px-4 md:px-8">
      <h3 className="text-2xl md:text-3xl font-bold text-gray-900 mb-8">Contact Us</h3>
      
      {/* Phone */}
      <div className="flex items-start mb-8">
        <span className="iconbox w-12 h-12 bg-teal-600 text-center p-3 rounded-full text-white flex items-center justify-center mr-4">
          <BsFillTelephoneFill className="text-xl" />
        </span>
        <div>
          <span className="block text-lg text-gray-700">Phone</span>
          <a href="tel:1234567890" className="block text-lg font-semibold text-gray-900">+91 xxxxxxxxxx</a>
        </div>
      </div>
      
      {/* Email */}
      <div className="flex items-start mb-8">
        <span className="iconbox w-12 h-12 bg-teal-600 text-center p-3 rounded-full text-white flex items-center justify-center mr-4">
          <FiMail className="text-xl" />
        </span>
        <div>
          <span className="block text-lg text-gray-700">Email</span>
          <a href="mailto:silverrosepublications@gmail.com" className="block text-lg font-semibold text-gray-900">silverrosepublications@gmail.com</a>
        </div>
      </div>
      
      {/* Address */}
      <div className="flex items-start">
        <span className="iconbox w-12 h-12 bg-teal-600 text-center p-3 rounded-full text-white flex items-center justify-center mr-4">
          <FaAddressCard className="text-xl" />
        </span>
        <div>
          <span className="block text-lg text-gray-700">Address</span>
          <p className="block text-lg font-semibold text-gray-900">India - 250002</p>
        </div>
      </div>
    </div>
  );
}

export default ContactUsLeft;
