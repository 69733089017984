import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-100 py-12">
      <div className="container mx-auto px-6 md:px-12">
        <div className="flex flex-col md:flex-row items-center justify-between text-center md:text-left">
          {/* Footer Text */}
          <p className="text-sm md:text-base mb-4 md:mb-0">
            &copy; 2024 <span className="font-semibold">Silver Rose Publications</span>. All Rights Reserved. Crafted with care by
            <a
              href="/"
              className="text-teal-300 hover:text-teal-100 transition-colors duration-300 font-semibold"
            >
              ~Shivanifilms
            </a>
          </p>
          {/* Social Media Links */}
          <div className="flex justify-center md:justify-start space-x-6 mt-4 md:mt-0">
            <a
              href="https://facebook.com"
              className="text-blue-600 hover:text-blue-400 text-xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://twitter.com"
              className="text-blue-400 hover:text-blue-300 text-xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com"
              className="text-pink-600 hover:text-pink-400 text-xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://linkedin.com"
              className="text-blue-700 hover:text-blue-500 text-xl transition-colors duration-300"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
        {/* Additional Info */}
        <div className="mt-8 text-center">
          <p className="text-sm md:text-base text-gray-400">
            Designed with <span className="text-red-500">&hearts;</span> by the team.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
