import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import { AiFillFileText } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import ClassData from '../Class.json';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';

const MobileHeader = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    };

    return (
        <div className="w-full">
            {/* Mobile Header */}
            <div className="bg-gradient-to-r from-[#1d4ed8] to-[#2563eb] text-white shadow-lg py-4 px-6 flex justify-between items-center md:hidden">
                <div className="logo">
                    <Link to="/">
                        <img src={logo} width={120} alt="Logo" />
                    </Link>
                </div>
                <button
                    onClick={toggleMenu}
                    className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30"
                >
                    {isMenuOpen ? (
                        <AiOutlineClose className="text-2xl" />
                    ) : (
                        <FiMenu className="text-2xl" />
                    )}
                </button>
            </div>

            {isMenuOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-[#1d4ed8] text-white z-50 flex flex-col">
                    <div className="p-4 flex justify-end">
                        <button
                            onClick={toggleMenu}
                            className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30"
                        >
                            <AiOutlineClose className="text-2xl" />
                        </button>
                    </div>
                    <ul className="flex flex-col p-4 space-y-4">
                        <li>
                            <Link
                                to="/"
                                className="block text-lg font-semibold hover:text-[#e0e0e0] transition duration-200"
                                onClick={() => setMenuOpen(false)}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <button
                                onClick={toggleSubMenu}
                                className="flex items-center justify-between w-full text-lg font-semibold hover:text-[#e0e0e0] transition duration-200"
                            >
                                Books
                                {isSubMenuOpen ? (
                                    <FaAngleDown className="text-lg" />
                                ) : (
                                    <FaAngleRight className="text-lg" />
                                )}
                            </button>
                            {isSubMenuOpen && (
                                <ul className="mt-2 pl-4 space-y-2 bg-[#2563eb] rounded-md">
                                    {ClassData.map(item => (
                                        <li key={item.id}>
                                            <Link
                                                to={item.direct}
                                                className="block py-2 px-4 hover:bg-[#1d4ed8] rounded-md transition duration-200"
                                                onClick={() => setMenuOpen(false)}
                                            >
                                                {item.classCategory}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className="block text-lg font-semibold hover:text-[#e0e0e0] transition duration-200"
                                onClick={() => setMenuOpen(false)}
                            >
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default MobileHeader;
