import React from 'react';

const MakeEnquiry = () => {
  return (
    <div className="make-enquiry w-full md:w-1/2 px-4 md:px-8">
      <h3 className="text-2xl md:text-3xl font-bold text-gray-900 mb-8">Make Enquiry</h3>
      <form action="https://getform.io/f/96415907-0bf4-4cb3-aea0-79e3a372fa86" method="post">
        
        <div className="grid gap-6 mb-8 md:grid-cols-2">
          <div>
            <label htmlFor="myName" className="block text-base font-semibold text-gray-700 mb-2">Name</label>
            <input
              type="text"
              name="Name"
              id="myName"
              placeholder="Enter Your Name"
              className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:border-teal-600 focus:ring-2 focus:ring-teal-200 outline-none"
            />
          </div>
          <div>
            <label htmlFor="myEmail" className="block text-base font-semibold text-gray-700 mb-2">Email</label>
            <input
              type="email"
              name="Email"
              id="myEmail"
              placeholder="Enter Your Email"
              className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:border-teal-600 focus:ring-2 focus:ring-teal-200 outline-none"
            />
          </div>
        </div>
        
        <div className="grid gap-6 mb-8 md:grid-cols-2">
          <div>
            <label htmlFor="myPhone" className="block text-base font-semibold text-gray-700 mb-2">Phone</label>
            <input
              type="tel"
              name="phone"
              id="myPhone"
              placeholder="Enter Your Phone"
              className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:border-teal-600 focus:ring-2 focus:ring-teal-200 outline-none"
            />
          </div>
          <div>
            <label htmlFor="mySubject" className="block text-base font-semibold text-gray-700 mb-2">Subject</label>
            <input
              type="text"
              name="subject"
              id="mySubject"
              placeholder="Enter Subject"
              className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:border-teal-600 focus:ring-2 focus:ring-teal-200 outline-none"
            />
          </div>
        </div>
        
        <div className="mb-8">
          <label htmlFor="myMessage" className="block text-base font-semibold text-gray-700 mb-2">Message</label>
          <textarea
            name="message"
            id="myMessage"
            placeholder="Enter Your Message"
            rows="6"
            className="w-full p-4 border border-gray-300 rounded-md shadow-sm focus:border-teal-600 focus:ring-2 focus:ring-teal-200 outline-none"
          ></textarea>
        </div>
        
        <button
          type="submit"
          className="bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-6 rounded-md transition duration-300"
        >
          Send Enquiry
        </button>
      </form>
    </div>
  );
}

export default MakeEnquiry;
