// import React from 'react';
// import './tailwind.css'
// import image from '../assets/images/book.png'

// const SlidingImageAnimation = () => {
//   return (
//     <div className="flex items-center overflow-hidden">
//       <div className="flex animation-slide">
//         <img src={image} alt="Image 1" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 2" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         <img src={image} alt="Image 3" className="w-48 h-auto m-4 rounded-2xl" />
//         {/* Add more images as needed */}
//       </div>
//     </div>
//   );
// };

// export default SlidingImageAnimation;




import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

// Import your images
import image1 from '../assets/images/book1.png'; 
import image2 from '../assets/images/book2.png'; 
import image3 from '../assets/images/book3.png'; 

const SlidingImageAnimation = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Animation easing
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <section className="relative w-full py-16 bg-gray-100 overflow-hidden">
      <div className="container mx-auto px-6">
        <h2 className="text-center text-3xl md:text-4xl font-bold mb-12">
          Explore Our Exclusive Collection
        </h2>
        <div className="flex items-center justify-center space-x-6 overflow-hidden">
          <div
            className="flex-shrink-0 w-full md:w-1/3 overflow-hidden"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src={image1}
              alt="Image 1"
              className="w-full h-auto rounded-lg shadow-lg transition-transform transform hover:scale-105"
            />
          </div>
          <div
            className="flex-shrink-0 w-full md:w-1/3 overflow-hidden"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <img
              src={image2}
              alt="Image 2"
              className="w-full h-auto rounded-lg shadow-lg transition-transform transform hover:scale-105"
            />
          </div>
          <div
            className="flex-shrink-0 w-full md:w-1/3 overflow-hidden"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <img
              src={image3}
              alt="Image 3"
              className="w-full h-auto rounded-lg shadow-lg transition-transform transform hover:scale-105"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SlidingImageAnimation;