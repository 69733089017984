import React from 'react';
import backgroundImage from '../assets/images/book5.jpg'; // Replace with your actual image path

const BeautifulTextWithMessage = ({ title, message }) => {
  return (
    <section className="relative w-full max-w-5xl mx-auto bg-gradient-to-r from-teal-400 to-blue-600 text-white rounded-lg overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>

      {/* Overlay for text readability */}
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>

      {/* Content Section */}
      <div className="relative z-10 p-8 md:p-12 text-center">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold mb-6 md:mb-8 leading-tight">
          {title}
        </h1>
        {/* Message */}
        <p className="text-base md:text-lg leading-relaxed md:leading-loose">
          {message}
        </p>
      </div>
    </section>
  );
};

export default BeautifulTextWithMessage;
