// import React from 'react'
// import ClassLists from '../Class.json'
// import { FaAngleRight } from 'react-icons/fa'
// import { Link } from 'react-router-dom'


// // classeslist category pages 
// const ClassesList = () => {
//   return (
//     <>
//           <div className="classesList bg-[#eee] py-8 px-4 w-full md:columns-4">
//               <ul >
//                 {
//                   ClassLists.map((item, id) => {
//                     return (
//                      <Link to={item.direct} key={id}>
//                       <li key={item.id} className='relative'>
//                         <span className='  text-[2em] text-[#84818138] absolute z-[11] bottom-1 right-3' >Class</span>
//                         <Link to={item.direct} className='p-4 bg-[#fff] mb-8 font-bold shadow-md block text-[#000] text-[2em] font-sans relative  '> {item.classCategory}
//                           <FaAngleRight className='before:content-[""] inline-block text-3xl absolute bottom-[22px]' />
//                         </Link>
//                       </li>
//                       </Link>

//                     )
//                   })
//                 }
//               </ul>
//             </div>

//     </>
//   )
// }

// export default ClassesList


import React from 'react';
import ClassLists from '../Class.json';
import { FaAngleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ClassesList = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">Class Categories</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {ClassLists.map((item, index) => (
            <Link to={item.direct} key={index} className="group relative bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform duration-300 hover:scale-105">
              <div className="p-6 flex flex-col justify-between">
                <div className="flex-grow">
                  <h3 className="text-xl font-semibold text-gray-900 mb-4">{item.classCategory}</h3>
                  <p className="text-gray-600 mb-4">Explore this category to find the most relevant classes and resources.</p>
                </div>
                <div className="flex items-center justify-between text-blue-600 font-semibold">
                  <span>Learn More</span>
                  <FaAngleRight className="text-xl transition-transform duration-300 group-hover:translate-x-2" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ClassesList;
