// import React from 'react';

// const EducationalResource = ({ title, imageUrl }) => {
//   return (
//     <div className="max-w-sm mx-auto bg-gradient-to-br from-blue-100 to-blue-200 border border-gray-300 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-500 hover:scale-105 hover:shadow-xl">
//       <div className="relative">
//         <img
//           className="w-full h-48 sm:h-64 object-cover"
//           src={imageUrl}
//           alt={title}
//         />
//         <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-40"></div>
//         <div className="absolute bottom-0 left-0 p-6">
//           <h2 className="text-2xl sm:text-3xl font-extrabold text-white">{title}</h2>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EducationalResource;
import React from 'react';

const EducationalResource = ({ title, imageUrl }) => {
  return (
    <div className="max-w-md mx-auto bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
      {/* Image Section */}
      <div className="relative">
        <img
          className="w-full h-60 object-cover rounded-t-lg"
          src={imageUrl}
          alt={title}
        />
      </div>
      
      {/* Content Section */}
      <div className="p-6">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">{title}</h2>
        <p className="text-gray-600">
          Dive into this resource to enhance your learning and gain new insights.
          This section can be expanded with additional details about the resource.
        </p>
      </div>
      
      {/* Button Section */}
      <div className="p-4 bg-gray-100">
        <a
          href="#"
          className="block text-center text-blue-600 font-semibold py-2 px-4 rounded-lg border border-blue-600 hover:bg-blue-50 transition-colors"
        >
          Learn More
        </a>
      </div>
    </div>
  );
};

export default EducationalResource;
