// import React, { useState } from 'react';
// import { useParams , useNavigate }  from 'react-router-dom';

// const EBookViewerPage = () => {
//   const { className, subjectName, fileId } = useParams();
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate();

//   if (!fileId || fileId === "") {
//     return (
//       <div className="pdf-container mt-11 mx-auto w-screen md:mt-24 md:p-10">
//         <div>Error:  file ID is missing.</div>
//       </div>
//     );
//   }
// //   const pdfUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;
//   //const pdfUrl = `https://drive.google.com/file/d/${fileId}/preview`;
// console.log(fileId)

// const handleLoad = () => {
//   setLoading(false); // PDF content is loaded
// };
//   return (<>

//    <div className="container mt-11 md:mt-24 ">
//    <p className='text-center uppercase text-2xl font-semibold'>{className} {subjectName}</p>
//     <div className="pdf-container  mx-auto w-screen  md:p-10">
//     {loading ? (
//         <div className="loading-spinner mt-24 text-4xl font-bold text-center">Loading...</div>
//       ) : null}
  
//       <iframe src={fileId} title="Embedded Flipbook" width="100%" height="100%" frameBorder="0"></iframe>
   
//     </div>
//    </div>
//   </>

//   );
// };

// export default EBookViewerPage;



import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
const formatSubjectName = (name) => {
  // Remove all digits from the string
  const cleanedName = name.replace(/\d+/g, '');

  // Convert to title case
  return cleanedName
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
const formatClassName = (name) => {
  // Add hyphens between letters and numbers
  const formattedName = name.replace(/(\D)(\d)/g, '$1-$2');
  
  // Convert to uppercase
  return formattedName.toUpperCase();
};
const EBookViewerPage = () => {
  const { className, subjectName } = useParams();
  const location = useLocation();
  const { fileId } = location.state || {}; // Retrieve fileId from state
  if (!fileId) {
    return (
      <div className="pdf-container mt-11 mx-auto w-screen md:mt-24 md:p-10">
        <div>Error: Flipbook file ID is missing.</div>
      </div>
    );
  }
  const flipbookUrl = `https://silverrosepublications.com/e-content/${fileId}`;
  console.log(flipbookUrl);

  const formattedClassName = formatClassName(className);
  const formattedSubjectName = formatSubjectName(subjectName);

  return (
    <div className="container mt-11 md:mt-24">
      <p className='text-center uppercase text-2xl font-semibold'>
        {formattedClassName} {formattedSubjectName}
      </p>
      <div className="flipbook-container mx-auto w-screen md:p-10">
        <iframe
          src={flipbookUrl}
          title="Embedded Flipbook"
          width="100%"
          height="600px"
          frameBorder="0"
          style={{ border: 'none' }}
        ></iframe>
      </div>
    </div>
  );
};

export default EBookViewerPage;
