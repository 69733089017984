import React from 'react';
import { BsFillPlayFill } from 'react-icons/bs';
import { SiBookstack } from 'react-icons/si';
import { AiOutlineFilePdf } from 'react-icons/ai';

const InnerGrid = () => {
  return (
    <section className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Animated Videos Card */}
          <div className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl">
            <div className="flex items-center justify-center w-20 h-20 bg-yellow-200">
              <BsFillPlayFill className="text-yellow-500 text-3xl" />
            </div>
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Animated Videos</h3>
              <p className="text-gray-600 hover:underline cursor-pointer">View and Read By Chapters</p>
            </div>
          </div>

          {/* E-Books Card */}
          <div className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl">
            <div className="flex items-center justify-center w-20 h-20 bg-blue-200">
              <SiBookstack className="text-blue-500 text-3xl" />
            </div>
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">E-Books</h3>
              <p className="text-gray-600 hover:underline cursor-pointer">Read E-Books</p>
            </div>
          </div>

          {/* Answer Key Card */}
          <div className="flex items-center bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl">
            <div className="flex items-center justify-center w-20 h-20 bg-green-100">
              <AiOutlineFilePdf className="text-green-500 text-3xl" />
            </div>
            <div className="p-4 flex-grow">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">Answer Key</h3>
              <p className="text-gray-600 hover:underline cursor-pointer">View and Download</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InnerGrid;
